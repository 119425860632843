// ==================================================
//  モバイル判定イベント
// ==================================================
function is_mobile() {
  const intFrameWidth = window.innerWidth;
  return (intFrameWidth <= 767) ? true : false;
}


// ==================================================
//  スクロールイベント
//    - 追従ヘッダーの横スクロール対応（PC）
// ==================================================
const header = document.querySelector(".js-headerFixed");
let scrollTicking = false;
document.addEventListener('scroll', (event) => {
  if (!scrollTicking) {
    requestAnimationFrame(() => {
      scrollTicking = false;
      header.style.left = -window.scrollX + 'px';
    });
    scrollTicking = true;
  }
},{ passive: true });


// ==================================================
//  ヘッダーメニューボタン（SP）
// ==================================================
const menu = document.querySelector(".js-headerMenu");
const menuButton = document.querySelector(".js-headerButton");
const menuActiveClass = "js-headerMenu-active";
function menuToggle() { menu.classList.toggle(menuActiveClass); }
menuButton.addEventListener('click', event => { menuToggle(); });


// ==================================================
//  アンカーリンクのスムーススクロール
//    - ヘッダーメニューボタン（SP）
// ==================================================
const anchorLinks = document.querySelectorAll('a[href^="#"]');
const anchorLinksArray = Array.prototype.slice.call(anchorLinks);
let positionAdjust = 122;
if (is_mobile()) { positionAdjust = 74; }
anchorLinksArray.forEach(link => {
  link.addEventListener('click', event => {
    event.preventDefault();
    const targetId = link.hash;
    if (targetId !== '') {
      const targetElement = document.querySelector(targetId);
      const targetOffsetTop = (window.pageYOffset + targetElement.getBoundingClientRect().top) - positionAdjust;
      window.scrollTo({
        top: targetOffsetTop,
        behavior: "smooth"
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }

    if (menu.classList.contains(menuActiveClass) == true) { menuToggle(); }
  });
});